import React from 'react';
import cs from 'classnames';
import { Arrow } from '../icons';

const Button = ({ action, label, variant, outline, hide }) => {
  const variantClass = `dc-button-${variant}`;

  const click = action ? {
    onClick: () => action()
  } : {};

  return (
    <button  {...click} className={cs('dc-button', { 'dc-button-outline': outline, [variantClass]: variant, 'dc-button-hide': hide })}>{label}
      <div className="dc-button-action">
        <div className='dc-button-icon'>
          <Arrow />
        </div>
      </div>
      <div className='dc-button-label'>
        {label}
      </div>
    </button>
  )
}

export default Button;